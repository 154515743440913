import React from "react";

function CircleMobVib({ size }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 906 906"
      {...size}
    >
      <g data-name="Group 1">
        <path
          data-name="Icon material-vibration"
          d="M0 265.41h43.485V134.955H0zm65.227 43.485h43.485V91.47H65.227zm413.107-173.94V265.41h43.485V134.955zm-65.227 173.94h43.485V91.47h-43.485zM358.751 4.5H163.069a32.57 32.57 0 00-32.614 32.614v326.137a32.57 32.57 0 0032.614 32.614h195.682a32.57 32.57 0 0032.614-32.614V37.114A32.57 32.57 0 00358.751 4.5zM347.88 352.38H173.94V47.985h173.94z"
          transform="translate(-407 -151) translate(599 403.5)"
          fill="#38B6FF"
        />
        <g
          data-name="Ellipse 1"
          transform="translate(-407 -151) translate(407 151)"
          fill="none"
          stroke="#38B6FF"
          strokeWidth={40}
        >
          <circle cx={453} cy={453} r={453} stroke="none" />
          <circle cx={453} cy={453} r={433} />
        </g>
      </g>
    </svg>
  );
}
export default CircleMobVib;
