import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD6lEqXz20uxHQoGtMCLZvJrOB1cUQWQA0",
  authDomain: "bircube-angels.firebaseapp.com",
  projectId: "bircube-angels",
  storageBucket: "bircube-angels.appspot.com",
  messagingSenderId: "842746962887",
  appId: "1:842746962887:web:fe6be0bab43d284c17efe2",
  measurementId: "G-92M99Q2V4Q",
};
const firebase = initializeApp(firebaseConfig);
// firebase.initializeApp(firebaseConfig);

const db = getFirestore();

export { db, firebase };
