import * as React from "react";

function PenWithNoteBook({ size }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 635 635"
      {...size}
    >
      <g data-name="Group 1">
        <g data-name="MDI / file-document-edit">
          <path
            data-name="Path / file-document-edit"
            d="M32.553 2A28.454 28.454 0 004 30.553v228.423a28.553 28.553 0 0028.553 28.553h57.106v-27.268l29.841-29.838H32.553V201.87h115.5l28.547-28.553H32.553v-28.553h172.6l27.27-27.264V87.659L146.764 2H32.553m99.935 21.415l78.52 78.52h-78.52v-78.52m102.076 135.626a7.863 7.863 0 00-5.711 2.284l-14.562 14.562 29.838 29.695 14.562-14.419a8.2 8.2 0 000-11.278l-18.559-18.559a7.772 7.772 0 00-5.568-2.284m-28.7 25.269l-87.657 87.8v29.7h29.695l87.8-87.8z"
            transform="translate(-276 -229) translate(422 375) translate(38.829 26.553)"
            fill="#ff9a4a"
          />
        </g>
        <g
          data-name="Ellipse 1"
          transform="translate(-276 -229) translate(276 229)"
          fill="none"
          stroke="#ff9a4a"
          strokeWidth={20}
        >
          <circle cx={317.5} cy={317.5} r={317.5} stroke="none" />
          <circle cx={317.5} cy={317.5} r={307.5} />
        </g>
      </g>
    </svg>
  );
}

export default PenWithNoteBook;
