import * as React from "react";

function DeliverCircle({ size }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 609 624"
      {...size}
    >
      <g data-name="MDI / email-outline">
        <path
          data-name="Path / email-outline"
          d="M202.9 23.372C202.9 12.717 193.863 4 182.813 4H22.09C11.041 4 2 12.717 2 23.372V139.6c0 10.654 9.041 19.372 20.09 19.372h160.723c11.05 0 20.09-8.717 20.09-19.372V23.372m-20.09 0L102.452 71.8 22.09 23.372h160.723m0 116.23H22.09V42.743l80.361 48.429 80.361-48.429z"
          fill="#ff9a4a"
          transform="translate(202 231)"
        />
      </g>
      <g data-name="Ellipse 1" fill="none" stroke="#ff9a4a" strokeWidth={20}>
        <ellipse cx={304.5} cy={312} rx={304.5} ry={312} stroke="none" />
        <ellipse cx={304.5} cy={312} rx={294.5} ry={302} />
      </g>
    </svg>
  );
}

export default DeliverCircle;
