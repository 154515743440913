import React, { useState, useEffect } from "react";
import { FieldArray, Form, Formik, getIn } from "formik";
// import { Divider, Grid, makeStyles, TextField } from "@mui/material";
// import { TextField } from "@material-ui/core";
import * as Yup from "yup";
import "../../assets/css/FormCss.css";
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  FormGroup,
} from "reactstrap";

import { db, firebase } from "../../Database/firebase";
import {
  collection,
  doc,
  // onSnapshot,
  onSnapshot,
  query,
  orderBy,
  getDocs,
  updateDoc,
  increment,
  where,
  addDoc,
  setDoc,
} from "firebase/firestore";
import { Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";

const Subscription = () => {
  const [usersList, setUsersList] = useState([]);

  const validationSchema = Yup.object().shape({
    userName: Yup.string().required("Name is Required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("email is required"),
    message: Yup.string().required("message is required"),
  });
  const debug = true;
  const handleSubmit = (values) => {
    const userName = values.userName;
    const email = values.email;
    const message = values.message;

    console.log("this the OnSubmit Values", values);
    CreateUser(userName, email, message);

    alert(JSON.stringify(values, null, 2));
    values.userName = "";
    values.email = "";
    values.message = "";
  };
  const CreateUser = async (userName, email, message) => {
    const docRef = await addDoc(collection(db, "ContactUs"), {
      name: userName,
      email: email,

      message: message,
    });
    console.log(docRef);
  };

  return (
    <>
      <div
        id="section-contact-us"
        className="section section-contact-us text-center"
      >
        <Container>
          <h2 className="title">Contact us?</h2>
          <p className="description">Your contact is very important to us.</p>
          {/* Welcome to Create user
      <br /> */}

          <Formik
            initialValues={{
              userName: "",
              email: "",
              message: "",
            }}
            onSubmit={async (values, { resetForm }) => {
              await handleSubmit(values);

              resetForm();
            }}
            validationSchema={validationSchema}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              isValid,
              setFieldValue,
              resetForm,
            }) => (
              <Form noValidate>
                <Grid container justify="center">
                  <Grid item xl="3" lg="3" md="2">
                    <div></div>
                  </Grid>
                  <Grid item xs="12" sm="12" xl="6" lg="6" md="8">
                    <TextField
                      name="userName"
                      id="outlined-basic"
                      label="Name"
                      placeholder="Enter your name"
                      variant="outlined"
                      fullWidth
                      size="small"
                      // focused
                      style={{ marginTop: "50px" }}
                      className="inputRounded"
                      autoComplete="off"
                      // placeholder="Enter your name"
                      value={values.userName}
                      onChange={handleChange}
                      error={touched.userName && Boolean(errors.userName)}
                      helperText={touched.userName && errors.userName}
                      // startAdornment={<InputAdornment position="start" />}
                    />
                  </Grid>
                  <Grid item xs="12" sm="12" xl="3" lg="3" md="2">
                    <div></div>
                  </Grid>
                </Grid>
                <Grid container justify="center">
                  <Grid item xl="3" lg="3" md="2">
                    <div></div>
                  </Grid>
                  <Grid item xs="12" sm="12" xl="6" lg="6" md="8">
                    <TextField
                      size="small"
                      style={{ marginTop: "50px" }}
                      className="inputRounded"
                      autoComplete="off"
                      name="userName"
                      id="outlined-basic"
                      // label=" "
                      label="Email"
                      placeholder="Enter your Email"
                      variant="outlined"
                      name="email"
                      // focused
                      fullWidth
                      value={values.email}
                      onChange={handleChange}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      // startAdornment={<InputAdornment position="start" />}
                    />
                  </Grid>
                  <Grid item xs="12" sm="12" xl="3" lg="3" md="2">
                    <div></div>
                  </Grid>
                </Grid>
                <Grid container justify="center">
                  <Grid item xl="3" lg="3" md="2">
                    <div></div>
                  </Grid>
                  <Grid item xs="12" sm="12" xl="6" lg="6" md="8">
                    <TextField
                      style={{ marginTop: "80px" }}
                      // borderRadius="20px"
                      variant="standard"
                      placeholder="Type your message"
                      id="message"
                      name="message"
                      fullWidth
                      // focused
                      autoComplete="off"
                      value={values.message}
                      onChange={handleChange}
                      error={touched.message && Boolean(errors.message)}
                      helperText={touched.message && errors.message}
                    />
                  </Grid>
                  <Grid item xs="12" sm="12" xl="3" lg="3" md="2">
                    <div></div>
                  </Grid>
                </Grid>
                <Grid container justify="center">
                  <Grid item xl="3" lg="3" md="2">
                    <div></div>
                  </Grid>
                  <Grid item xs="12" sm="12" xl="6" lg="6" md="8">
                    <div
                      className="send-button"
                      style={{
                        marginTop: "30px",
                      }}
                    >
                      <Button
                        className="btn-round"
                        block
                        // color="#FF9A4A"
                        style={{ backgroundColor: "#FF9A4A" }}
                        // href="#pablo"
                        type="submit"
                        onClick={() => resetForm}
                        size="lg"
                      >
                        Send Message
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs="12" sm="12" xl="3" lg="3" md="2">
                    <div></div>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Container>
      </div>
    </>
  );
};

export default Subscription;
