import { Avatar, Box, Grid, Paper, styled } from "@mui/material";
import { pink } from "@mui/material/colors";
import { display } from "@mui/system";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import CircleMobVib from "./circle/CircleMobVib";
import DeliverCircle from "./circle/DeliverCircle";
import PenWithNoteBook from "./circle/PenWithNoteBook";

function ParwaIntro() {
  const [circleWidth, setCircleWidth] = React.useState("");
  const [font_Size, setFonSize] = React.useState("");
  const [screenWidth, setscreeneWidth] = React.useState();

  const introBorder1 = {
    borderTop: "8px solid #FF9A4A",
    borderBottom: "8px solid #FF9A4A",
    height: 300,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const introBorder = {
    borderTop: "8px solid #38B6FF",
    borderBottom: "8px solid #38B6FF",
    height: 300,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const introText = {
    display: "flex",
    flexDirection: "column",
    height: 250,
    alignItems: "center",
    justifyContent: "center",
  };
  const itroHeader = {};
  React.useEffect(() => {
    // if (window.innerWidth > 991) {
    //   setCircleWidth("500px");
    //   setFonSize("100px");
    // } else {
    //   setCircleWidth("200px");
    //   setFonSize("100px");
    // }
  }, []);

  return (
    <>
      <div className="section section-team text-center">
        <Container>
          <Box
            style={{ marginTop: "100px", marginBottom: "200px" }}
            sx={{ flexGrow: 1 }}
          >
            <Grid container spacing={0}>
              <Grid item xs={12} md={4}>
                <PenWithNoteBook size={300} />
              </Grid>

              <Grid item xs={12} md={4}>
                <div style={introBorder1}>
                  <p
                    style={{
                      fontSize: "160px",
                      color: "#FF9A4A",
                      fontWeight: "bolder",
                    }}
                  >
                    1
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div style={introBorder1}>
                  <div style={introText}>
                    <p
                      style={{
                        fontSize: "40px",
                        // color: "#38B6FF",
                        fontWeight: "bolder",
                      }}
                    >
                      Share
                    </p>
                    <p
                      style={{
                        fontSize: "25px",
                        // color: "#38B6FF",
                        fontWeight: "bold",
                        width: "250px",
                      }}
                    >
                      Share your prescription
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
          <Box
            style={{ marginTop: "100px", marginBottom: "200px" }}
            sx={{ flexGrow: 1 }}
          >
            <Grid container spacing={0}>
              <Grid item xs={12} md={4}>
                <div style={introBorder}>
                  <div style={introText}>
                    <p
                      style={{
                        fontSize: "40px",
                        // color: "#38B6FF",
                        fontWeight: "bolder",
                      }}
                    >
                      Remind
                    </p>
                    <p
                      style={{
                        fontSize: "25px",
                        // color: "#38B6FF",
                        fontWeight: "bold",
                        width: "250px",
                      }}
                    >
                      Get your timely reminder
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div style={introBorder}>
                  <p
                    style={{
                      fontSize: "160px",
                      color: "#38B6FF",
                      fontWeight: "bolder",
                    }}
                  >
                    2
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <CircleMobVib size={300} />
              </Grid>
            </Grid>
          </Box>
          <Box style={{ marginTop: "100px" }} sx={{ flexGrow: 1 }}>
            <Grid container spacing={0}>
              <Grid item xs={12} md={4}>
                <DeliverCircle size={300} />
              </Grid>

              <Grid item xs={12} md={4}>
                <div style={introBorder1}>
                  <p
                    style={{
                      fontSize: "160px",
                      color: "#FF9A4A",
                      fontWeight: "bolder",
                    }}
                  >
                    3
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div style={introBorder1}>
                  <div style={introText}>
                    <p
                      style={{
                        fontSize: "40px",
                        // color: "#38B6FF",
                        fontWeight: "bolder",
                      }}
                    >
                      Deliver
                    </p>
                    <p
                      style={{
                        fontSize: "25px",
                        // color: "#38B6FF",
                        fontWeight: "bold",
                        width: "250px",
                      }}
                    >
                      Get the medicine delivered at your doorstep
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
    </>
  );
}

export default ParwaIntro;
