import React from "react";
import { Button, Col, Container, Row } from "reactstrap";

function OurrTeam() {
  const print = {
    color: "9A9A9A",
    fontWeight: 300,
    lineHeight: "1.61em",
    fontSize: " 1.2em",
  };
  const text = {
    color: "9A9A9A",
    fontWeight: 300,
    lineHeight: "1.61em",
    fontSize: " 1.2em",
  };
  return (
    <>
      <div id="team-section" className="section section-team text-center">
        <Container>
          <h2 className="title">Here is our team</h2>
          <div className="team">
            <Row>
              {" "}
              <Col md="4">
                <div className="team-player">
                  <img
                    alt="..."
                    style={{ width: "100px", height: "100px" }}
                    className="rounded-circle img-fluid img-raised"
                    src={require("assets/img/asem1.jpg").default}
                  ></img>
                  <h4 className="title">Asem Khan</h4>
                  <p className="category text-info">DESIGNER/STRATEGIST</p>
                  <p className="description">
                    Yo! This is Asem, naturally and artificially flavored with
                    art and science but Black is my color. Helping people with
                    plan and strategy is something i love to do. My team mates
                    say I got humor in me, maybe that is cherry on top? Let's
                    connect!
                  </p>
                  <Button
                    className="btn-icon btn-round"
                    color="info"
                    href="http://www.instagram.com/asemfkhan"
                    // onClick={(e) => e.preventDefault()}
                    target="_blank"
                  >
                    <i className="fab fa-instagram"></i>
                  </Button>
                  <Button
                    className="btn-icon btn-round"
                    color="info"
                    href="https://www.linkedin.com/in/asem-khan-2782079b/"
                    target="_blank"
                    // onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-linkedin"></i>
                  </Button>
                </div>
              </Col>
              <Col md="4">
                <div className="team-player">
                  <img
                    alt="..."
                    style={{ width: "100px", height: "100px" }}
                    className="rounded-circle img-fluid img-raised"
                    src={require("assets/img/Farooq.png").default}
                  ></img>
                  <h4 className="title">Farooq Khan</h4>
                  <p className="category text-info">TECH GURU</p>
                  <p className="description">
                    I am an ordinary person with a blessed heart. like to be
                    friendly and frankly with every one. I believe in "Do good
                    have good"
                  </p>
                  <Button
                    className="btn-icon btn-round"
                    color="info"
                    href="https://www.facebook.com/profile.php?id=100051057773069"
                    target="_blank"

                    // onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-facebook-square"></i>
                  </Button>
                  <Button
                    className="btn-icon btn-round"
                    color="info"
                    href="https://www.instagram.com/farooqderghami/"
                    target="_blank"
                    // onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-instagram"></i>
                  </Button>
                </div>
              </Col>
              <Col md="4">
                <div className="team-player">
                  <img
                    alt="..."
                    style={{ width: "100px", height: "100px" }}
                    className="rounded-circle img-fluid img-raised"
                    src={require("assets/img/AsadJ.jpg").default}
                  ></img>
                  <h4 className="title">Muhammad Asad</h4>
                  <p className="category text-info">DEEP CODER</p>
                  <p className="description">
                    Hey ! This is Muhammad Asad, working as a junior software
                    engineer, interested in learning new things about technology
                    and engineering, His favourite animal is Lion because Lion
                    is a King of the forest and I am the King of software.
                  </p>
                  <Button
                    className="btn-icon btn-round"
                    color="info"
                    href="https://www.instagram.com/dev_asad.k/"
                    // onClick={(e) => e.preventDefault()}
                    target="_blank"
                  >
                    <i className="fab fa-instagram"></i>
                  </Button>
                  <Button
                    className="btn-icon btn-round"
                    color="info"
                    href="https://twitter.com/Muhamma79876157?t=cIAOCE4g_lts0aTRfK5UiQ&s=09"
                    target="_blank"
                    // onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-twitter"></i>
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div className="team-player">
                  <img
                    alt="..."
                    className="rounded-circle img-fluid img-raised"
                    style={{ width: "100px", height: "100px" }}
                    src={require("assets/img/ibrar.png").default}
                  ></img>
                  <h4 className="title">Muhammad Ibrar</h4>
                  <p className="category text-info">CSR</p>
                  <p className="description">
                    Wakey wakey! Call me Ebi, I am expert in communication with
                    people in a friendly way to provide the best customer
                    services. I love social media. Cheers!
                  </p>

                  <Button
                    className="btn-icon btn-round"
                    color="info"
                    href="http://www.instagram.com/ebi_hedonistic"
                    // onClick={(e) => e.preventDefault()}
                    target="_blank"
                  >
                    <i className="fab fa-instagram"></i>
                  </Button>
                  <Button
                    className="btn-icon btn-round"
                    color="info"
                    href="https://www.linkedin.com/in/muhammad-ibrar-7511b4117"
                    target="_blank"
                    // onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-linkedin"></i>
                  </Button>
                </div>
              </Col>
              <Col md="4">
                <div className="team-player">
                  <img
                    alt="..."
                    style={{ width: "100px", height: "100px" }}
                    className="rounded-circle img-fluid img-raised"
                    src={require("assets/img/shafqat.jpeg").default}
                  ></img>
                  <h4 className="title">Shafqat Ullah Khan</h4>
                  <p className="category text-info">CODER</p>
                  <div className="description">
                    <div style={{ display: "flex", textAlign: "center" }}>
                      <span style={print}> print</span>
                      <span style={text}>(‘Greetings!’)</span>
                    </div>
                    <div style={{ display: "flex", textAlign: "center" }}>
                      <span style={print}> print</span>
                      <span style={text}>
                        {" "}
                        (‘My name is Shafqat Ullah Khan’)
                      </span>
                    </div>
                    <div style={{ display: "flex", textAlign: "center" }}>
                      <span style={print}> print</span>
                      <span style={text}>
                        {" "}
                        (‘I am passionate about Technology’)
                      </span>
                    </div>
                    <div style={{ display: "flex", textAlign: "center" }}>
                      <span style={print}> print</span>
                      <span style={text}>
                        (‘Want to help world with my skills’)
                      </span>
                    </div>
                    <div style={{ display: "flex", textAlign: "center" }}>
                      <span style={print}> print</span>
                      <span style={text}>(‘Lets get started :)’)</span>
                    </div>
                  </div>

                  <Button
                    className="btn-icon btn-round"
                    color="info"
                    href="https://www.facebook.com/shafqat.khan.792740"
                    target="_blank"
                    // onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-facebook-square"></i>
                    {/* <i className="fab fa-google-plus"></i> */}
                  </Button>
                  {/* <Button
                    className="btn-icon btn-round"
                    color="info"
                    href="https://www.facebook.com/shafqat.khan.792740"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-youtube"></i>
                  </Button> */}
                  <Button
                    className="btn-icon btn-round"
                    color="info"
                    href="https://www.linkedin.com/in/shafqat-ullah-khan-25a9b8111/"
                    // onClick={(e) => e.preventDefault()}
                    target="_blank"
                  >
                    <i className="fab fa-linkedin"></i>
                    {/* <i className="fab fa-twitter"></i> */}
                  </Button>
                </div>
              </Col>
              <Col md="4">
                <div className="team-player">
                  <img
                    alt="..."
                    className="rounded-circle img-fluid img-raised"
                    style={{ width: "100px", height: "100px" }}
                    src={require("assets/img/Shahab Ali-min.png").default}
                  ></img>
                  <h4 className="title">Shahab Ali</h4>
                  <p className="category text-info">Designer</p>
                  <p className="description">
                    Hi! This is Shahab Ali, enthusiastic about Graphics and Art,
                    and like to study literature. Want to help people in need
                    with my skills and strategies.
                  </p>
                  <Button
                    className="btn-icon btn-round"
                    color="info"
                    target="_blank"
                    // href="https://twitter.com/Afridishaha8"
                    href="https://twitter.com/Afridishaha8"
                    // onClick={(e) => e.preventDefault()}
                    target="_blank"
                  >
                    <i className="fab fa-twitter"></i>
                  </Button>
                  <Button
                    className="btn-icon btn-round"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-instagram"></i>
                  </Button>
                  <Button
                    className="btn-icon btn-round"
                    color="info"
                    href="https://www.instagram.com/ali_shahab_0098/"
                    target="_blank"
                    // onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-linkedin"></i>
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="4" style={{ margin: "auto" }}>
                <div className="team-player">
                  <img
                    alt="..."
                    style={{ width: "100px", height: "100px" }}
                    className="rounded-circle img-fluid img-raised"
                    src={require("assets/img/waqqas.png").default}
                  ></img>
                  <h4 className="title">Waqas Khan</h4>
                  <p className="category text-info">TECHI</p>
                  <p className="description">
                    Hey Pal! This is waqas khan. I am a front-end developer
                    responsible for designing mobile Apps. I am curious about
                    learning new things. Let's explore!
                  </p>
                  <Button
                    className="btn-icon btn-round"
                    color="info"
                    href="https://www.facebook.com/mwaqaskhanroghani"
                    // onClick={(e) => e.preventDefault()}
                    target="_blank"
                  >
                    <i className="fab fa-facebook-square"></i>
                    {/* <i className="fab fa-twitter"></i> */}
                  </Button>
                  <Button
                    className="btn-icon btn-round"
                    color="info"
                    href="https://www.linkedin.com/in/waqaskhanroghani/"
                    target="_blank"
                    // onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-linkedin"></i>
                  </Button>
                </div>
              </Col>
            </Row>
            <Row></Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default OurrTeam;
