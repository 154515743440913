import { fontWeight } from "@mui/system";
import React from "react";
import ReactPlayer from "react-player";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";
import "../../assets/vidios/Crusuel.css";
// import hosainBro from "../../assets/vidios/hosainBro.mp4";
// import salman_parwafinal from "../../assets/vidios/salman_parwafinal.mp4";

// core components

// const items = [
//   {
//     src: require("assets/img/bg1.jpg").default,
//     altText: "Nature, United States",
//     caption: "Nature, United States",
//   },
//   {
//     src: require("assets/img/bg3.jpg").default,
//     altText: "Somewhere Beyond, United States",
//     caption: "Somewhere Beyond, United States",
//   },
//   {
//     src: require("assets/img/bg4.jpg").default,
//     altText: "Yellowstone National Park, United States",
//     caption: "Yellowstone National Park, United States",
//   },
// ];

function CarouselSection() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const videoProperty = [
    {
      id: 1,
      title: "hosainBro",
      src: "https://www.youtube.com/watch?v=O6Chv8rSw-E&t=1s&ab_channel=BircubeTechnologies",
      // credit: "YOUr",
    },
    {
      id: 2,
      title: "Salaman",
      src: "https://www.youtube.com/watch?v=q9xD3UeQAvI&t=1s&ab_channel=BircubeTechnologies",
      // credit: "Asim",
    },
  ];

  // const handleSelect = (selectedIndex, e) => {
  //   setIndex(selectedIndex);
  // };

  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === videoProperty.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? videoProperty.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div className="section" id="carousel">
        <Container>
          <h4
            style={{
              width: "300px",
              margin: "auto",
              marginBottom: "40px",
              fontWeight: "bolder",
            }}
          >
            What People Say About Us
          </h4>

          <Row className="justify-content-center">
            <Col lg="8" md="12">
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                interval={null}
              >
                <CarouselIndicators
                  items={videoProperty}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {videoProperty.map((item) => {
                  return (
                    <CarouselItem
                      onExiting={onExiting}
                      onExited={onExited}
                      key={item.src}
                    >
                      <ReactPlayer
                        url={item.src}
                        // light="https://sampleimage.com"
                        // url="https://youtu.be/O6Chv8rSw-E"
                        width="100%"
                        height={"400px"}
                        pip={true}
                        controls={true}
                        onPlay
                      />
                    </CarouselItem>
                  );
                })}
                <a
                  className="carousel-control-prev"
                  // data-slide="prev"
                  data-interval="false"
                  href="prev"
                  onClick={(e) => {
                    e.preventDefault();
                    previous();
                  }}
                  role="button"
                >
                  <i className="now-ui-icons arrows-1_minimal-left"></i>
                </a>
                <a
                  className="carousel-control-next"
                  // data-slide="next"
                  data-interval="false"
                  href="next"
                  onClick={(e) => {
                    e.preventDefault();
                    next();
                  }}
                  role="button"
                >
                  <i className="now-ui-icons arrows-1_minimal-right"></i>
                </a>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
{
  /* <div className="carousel-caption d-none d-md-block">
//{" "}
</div> */
}

export default CarouselSection;
