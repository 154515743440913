import * as React from "react";
// import Button from "@mui/material/Button";
import { Button, Container } from "reactstrap";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
function FaqDialogue() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const question = {
    color: "#FF9A4A",
    fontSize: "20px",
    fontWeight: "bold",
    fontFamily: "Montserrat",
    marginTop: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
  };
  const answer = {
    // color: "#FF9A4A",
    fontSize: "20px",
    fontWeight: "bold",
    fontFamily: "Montserrat",
    paddingTop: "5px",
    paddingLeft: "30px",
    paddingRight: "30px",
    // marginTop: "40px",
  };
  const address = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "50px",
  };
  return (
    <>
      {" "}
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          //   block
          className="btn-round login-btn"
          // color="#FF9A4A"
          style={{
            margin: "auto",

            fontSize: "20px",
            width: "200px",
          }}
          onClick={handleClickOpen("paper")}
          size="lg"
        >
          FAQ's
        </Button>

        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth="true"
          maxWidth="md"
          //   style={{ width: "90%" }}
        >
          <DialogTitle
            style={{
              fontSize: "35px",
              fontFamily: "Montserrat",
              fontWeight: "bolder",
              color: "#FF9A4A",
            }}
            id="scroll-dialog-title"
          >
            FAQ's
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
              //   style={{ display: "flex", : "center" }}
            >
              <div style={question}>
                How much do you have charge for delivery?
              </div>
              <div style={answer}>
                Delivery is free! there are no charges on delivery.
              </div>
              <div style={question}>How are we going to pay?</div>
              <div style={answer}>
                You can use EasyPaisa or COD (Cash On Delivery)
              </div>
              <div style={question}>
                Dose your services include reminder and updates?
              </div>
              <div style={answer}>
                Yes, our service includes reminders and update regarding your
                medicine via phone call as well as on whatsApp for your ease.
              </div>
              <div style={question}>Is there any app for Parwa?</div>
              <div style={answer}>No, but soon we are going to launch it.</div>
              <div style={question}>
                How Parwa is different than the local shop?
              </div>
              <div style={answer}>
                Parwa is different in two ways. To begin with, we keep track of
                your medicine so you would never get short of. In addition, we
                deliver the medicine at your doorstep for free.
              </div>{" "}
              <div style={question}>Dou you have a facebook page?</div>
              <div style={answer}>
                Yes, @parwa.pk or scan the QR code below.
              </div>
              <div style={address}>
                <img
                  style={{ width: "220px", marginBottom: "30px" }}
                  src={require("assets/img/qr-code.png").default}
                ></img>
                <p>WWW.parwa.pk</p>
                <p>+92 313 9111467</p>
                <p>Bircube, Mardan, Trade Center, PRC,Mardan</p>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className="btn-round login-btn" onClick={handleClose}>
              Cancel
            </Button>
            {/* <Button onClick={handleClose}>Subscribe</Button> */}
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default FaqDialogue;
