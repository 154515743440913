/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { Button, Container } from "reactstrap";
// core components

function IndexHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div id="home" className="page-header clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/header-compressed.jpg").default +
              ")",
          }}
          ref={pageHeader}
        ></div>
        <Container>
          <div className="content-center brand">
            <img
              alt="..."
              className="n-logo"
              src={require("assets/img/parwa(2).png").default}
            ></img>
            <h3 style={{ fontWeight: "bolder" }} className="h1-seo">
              Parwa
            </h3>
            <h3 style={{ fontWeight: "bolder" }}>
              Free delivery of medicine for our elders
            </h3>
          </div>
          <h6 className="category category-absolute">
            <Button
              className="btn-round login-btn"
              // style={{ backGroundColor: "#FF9A4A" }}
              // color="#FF9A4A"
              style={{ color: "white", margin: "auto" }}
              // to="/login-page"
              outline
              size="lg"
              href="Our Team"
              onClick={(e) => {
                e.preventDefault();
                document
                  .getElementById("section-contact-us")
                  .scrollIntoView({ behavior: "smooth" });
              }}
              // tag={Link}
            >
              Subscribe us
            </Button>
          </h6>
          {/* <div className="content-center brand">
            <img
              alt="..."
              className="n-logo"
              src={require("assets/img/parwa.png").default}
            ></img>
            <h1 className="h1-seo">Parwa</h1>
          </div>
          <h3>A beautiful Bootstrap 4 UI kit. Yours free.</h3>
          <h4 className="category category-absolute" style={{ color: "white" }}>
            Free delivery of medicine for our elders .
          </h4>
          <h3>
            {" "}
            <div className="col text-center">
              <Button
                className="btn-round login-btn"
                // style={{ backGroundColor: "#FF9A4A" }}
                // color="#FF9A4A"
                style={{ color: "white" }}
                to="/login-page"
                outline
                size="lg"
                tag={Link}
              >
                Subscibe us
              </Button>
            </div>
          </h3> */}
        </Container>
      </div>
    </>
  );
}

export default IndexHeader;
